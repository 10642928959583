.navbar{
    background-color: var(--yellow-theme-nav-background-color);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;

    &_container{
        display:flex;
        align-items: center;
        width: 100%;
        height: inherit;
        padding: 0px 20px;
        flex: 1;

        &_logo{
           
            cursor: pointer;
        }
        &_menu{
            display: flex;
            list-style: none;
            text-align: center;

            &_item{
                line-height: 40px;
                margin-right: 1.5rem;
                font-size: 1.6rem;

                &::after{
                    content: '';
                    display: block;
                    height: 3px;
                    width: 0;
                    background: transparent;
                    transition: all 0.5s ease;
                }

                &:hover::after{
                    width:100%;
                    background: var(--yellow-theme-main-color);

                }

                &_links{
                    color: var(--yellow-theme-main-color);
                    text-decoration: none;
                    padding: 0.5rem;
                }
            }
        }
    }

}

.nav-icon{
    display: none;

}

@media only screen and (max-width : 768px){
    .navbar_container_menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;

        &.active{
            background: #181818;
            left: 0px;
            opacity: 1;
        }
    }

    .nav-icon{
        display: block;
        cursor: pointer;
        color: var(--yellow-theme-main-color);
    }
}