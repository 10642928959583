.about{
        &__content{
             display: flex;
             gap:20px;
             padding: 20px;
             width: 100%;
             box-sizing: border-box;
             &__serviceWrapper{
                     h3{
                             margin-bottom: 10px;
                             font-size: 1.4rem;
                             font-weight: 700;
                             color: var(--yellow-theme-main-color);
                     }
             }
     
             &__personalWrapper,&__serviceWrapper{
                     width: 50%;
     
                     
             }
             &__personalWrapper{
                     h3{
                             margin-bottom: 10px;
                             font-size: 3.5rem;
                             font-weight: 700;
                             color: var(--yellow-theme-main-color);
                     }
                     p{
                        color: var(--yellow-theme-sub-text-color);
                        font-size: 1.9rem;
                        line-height: 1.65;
                        padding-bottom: 20px;
                        border-bottom: 1px dashed var(--yellow-theme-main-color);
                        
                     }
             }
             &__servicesWrapper{
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     margin-top: 40px;
                     margin-left: 200px;
                 &__innerContent{
                        width: 350px;
                        height: 350px;
                        background: radial-gradient(180px,var(--yellow-theme-main-color),transparent 90%);
                        position: relative;
                        border-radius: 50%;
                        border: 1.5px solid var(--yellow-theme-main-color);
                        animation: rotation 10s linear 1s infinite;

                        div:nth-child(1){
                             position: absolute; 
                             top: -9%; 
                             left: 41%; 
                        }

                        div:nth-child(2){
                             position: absolute; 
                             top: 41.5%; 
                             right: -8%; 
                                
                        }

                        div:nth-child(3){
                                position: absolute;
                                bottom: -30px;
                                left: 41%;
                                
                        }

                        div:nth-child(4){
                                position: absolute;
                                left: -8%;
                                top: 41%;
                                
                        }
                        
                 }
             }
     
     
        }
     }

     @keyframes rotation {
        100% {
            transform: rotate(360deg);
        }
     }