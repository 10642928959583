.contact{
    &__content{
        padding: 20px;

        &__header-text{
            color: var(--yellow-theme-sub-text-color);
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: 0.5px;
            position: relative;
            text-transform: uppercase;
            padding: 5px 10px;
            border-bottom: 2px solid var(--yellow-theme-main-color);
        }

        &__header-text::before,
        &__header-text::after{
            content: "";
            position: absolute;
            width: 2px;
            height: 5px;
            background-color: var(--yellow-theme-main-color);
            bottom: -1px;
        }
        &__header-text::before{
            left: 0;
        }
        &__header-text::after{
            right: 0;
        }
        &__form{
            margin-top: 30px;

            &__control{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                margin-bottom: 30px;

                div{
                    position: relative;
                    .inputName,
                    .inputEmail,
                    .inputDesc{
                        width: 100%;
                        padding: 16px;
                        border: 2px solid var(--yellow-theme-sub-text-color);
                        position: relative;
                        background-color: transparent;
                        transition: all 0.5s ease;
                        
                    }

                    .labelName,
                    .labelEmail,
                    .labeldesc{
                        position: absolute;
                        font-weight: 500;
                        top: 7px;
                        left: 12px;
                        transition: all 0.2s ease;
                        color: var(--yellow-theme-sub-text-color);
                        font-size: 16px;
                    }
                    .inputName:focus,
                    .inputEmail:focus,
                    .inputDesc:focus{
                        outline: none;

                    }
                    .inputName:focus + .labelName,
                    .inputName:valid + .labelName,
                    .inputEmail:focus + .labelEmail,
                    .inputEmail:valid + .labelEmail,
                    .inputDesc:focus + .labeldesc,
                    .inputDesc:valid + .labeldesc

                    {
                        font-size: 18px;
                        top: -25px;
                        left: 0px;
                    }
                }
            }
        }
        button{
            cursor: pointer;
            padding: 10px 70px;
            font-size: 2rem;
            background-color: transparent;
            color: var(--yellow-theme-main-color);
            border: 1.5px solid var(--yellow-theme-main-color);
            border-radius: 4px;
            font-weight: 600;

            &:hover{
                background-color: var(--yellow-theme-main-color);
                color: #000;
            }
        }
    }
}